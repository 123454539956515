import { ChangeEvent, useEffect, useState } from 'react';
import { DocumentsErreur } from '../../../../shared/class/DocumentsErreur';
import LabelCustom from '../labelCustom/LabelCustom';
import { InputFileVhlProps } from './InputFileVhlType';
import { BoxFileNameIconDeleteInputFileVhl, BoxFileNameInputFileVhl, BoxGlobalInputFileVhl, BoxIconLabelVhl, BoxInputFileVhl, BoxInputFileVhlIconClose, CameraVhl, ErrorInputFileVhl, FileNameInputFileVhl, IconRestFileVhl, InputFileVhlField, LabelInputFileVhl } from './InputFileVhlStyle';

const InputFileVhl = ({ label, setFile, setError, error, nameError, require = false }: InputFileVhlProps): JSX.Element => {

    const [colorBorder, setcolorBorder] = useState<string>('#e0e0e0');
    const [colorLabel, setcolorLabel] = useState<string>('#616161');
    const [nameDoc, setNameDoc] = useState<string[]>([]);

    const [countFile, setcountFile] = useState<number>(0);
    const [countFileDisplay, setCountFileDisplay] = useState<number>(8);

    useEffect(() => {
        if (!nameDoc.length) {
            setError('');
            setcolorBorder('#e0e0e0');
            setcolorLabel('#616161'); 
        };
    }, [nameDoc, setError]);

    const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => { 

        setError('');
        setcolorBorder('#e0e0e0');
        setcolorLabel('#616161');

        if (countFile >= 8) {
            setError((error: DocumentsErreur) => {
                return { ...error, [nameError]: 'Le nombre maximum de photos du véhicule est de 8' };
            });

            return;
        };

        if (e.target.files && e.target.files.length) {
            if (e.target.files.length > 8) {
                setFile([]);
                setcolorBorder('#e0e0e0');
                setcolorLabel('#616161');
                setError((error: DocumentsErreur) => {
                    return { ...error, [nameError]: 'Le nombre maximum de photos du véhicule est de 8' };
                });

                return;
            };

            Array.from(e.target.files).forEach((file: File) => {
                if (nameDoc.includes(file.name)) {
                    setError((error: DocumentsErreur) => {
                        return { ...error, [nameError]: 'Une photo du même nom existe déjà' };
                    });

                    return;
                };

                if (file.size >= 67000000) {
                    setError((error: DocumentsErreur) => {
                        return { ...error, [nameError]: 'La limite est de 67ko par photo' };
                    });

                    return;
                };

                if (file.type !== "image/jpeg" && file.type !== "image/png") {
                    setError((error: DocumentsErreur) => {
                        return { ...error, [nameError]: 'Le format dois être .jpeg ou .png' };
                    });
    
                    return;
                };

                setNameDoc((curr: string[]) => file && file.name ? [...curr, file.name] : [...curr]);
                setcountFile((curr: number) => curr + 1);
                setCountFileDisplay((curr: number) => curr - 1);
            });

            setFile(e.target.files);
            e.target.files[0] ? setcolorBorder('green') : setcolorBorder('#e0e0e0');
            e.target.files[0] ? setcolorLabel('green') : setcolorLabel('#616161');
        };
    };

    const deleteFile = (nameFile: string) => {
        setFile((curr: FileList) => Array.from(curr).filter((currfile => currfile.name !== nameFile)));
        setNameDoc((curr: string[]) => Array.from(curr).filter((currfile => currfile !== nameFile)));
        setcountFile((curr: number) => curr - 1);
        setCountFileDisplay((curr: number) => curr + 1);
    };

    return (
        <BoxGlobalInputFileVhl>

            {label !== '' && <LabelCustom label={label} require={require} />}

            <BoxInputFileVhlIconClose>
                <BoxInputFileVhl $color={colorBorder}>
                    <BoxIconLabelVhl>
                        <CameraVhl $colorLabel={colorLabel} />
                        <LabelInputFileVhl $colorLabel={colorLabel}>

                            {countFileDisplay !== 0 ? `Ajouter ${countFileDisplay} photos du véhicule maximum` : 'Le maximum de 8 photos est atteint'}
                        </LabelInputFileVhl>
                    </BoxIconLabelVhl>

                    <InputFileVhlField
                        onChange={onChangeFile}
                        type="file"
                        capture="environment"
                        multiple={true}
                        max={8}
                        accept="image/png, image/jpeg"
                    />
                </BoxInputFileVhl>
            </BoxInputFileVhlIconClose>

            {error && <ErrorInputFileVhl>{error}</ErrorInputFileVhl>}

            <BoxFileNameInputFileVhl>
                {nameDoc.map((name, index) => (
                    <BoxFileNameIconDeleteInputFileVhl key={index}>
                        <FileNameInputFileVhl>{index + 1} / {name}</FileNameInputFileVhl>
                        <IconRestFileVhl onClick={() => deleteFile(name)} />
                    </BoxFileNameIconDeleteInputFileVhl>
                ))}
            </BoxFileNameInputFileVhl>
        </BoxGlobalInputFileVhl>
    );
};

export default InputFileVhl;

//